import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './LanguageComponent.css';
import { useTranslation } from 'react-i18next';

const LanguageComponent = ({setCounter,counter}) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(() => localStorage.getItem('language') || 'en');

  // Function to toggle the language
  const toggleLanguage = () => {
    const newLanguage = language === 'tr' ? 'en' : 'tr'; // Use current state to determine new language
    setLanguage(newLanguage); // Update state with new language
    setCounter(counter+1)
    i18n.changeLanguage(newLanguage); // Change language using i18next with the correct new language value
    localStorage.setItem('language', newLanguage); // Save the selected language to localStorage
  };

  useEffect(() => {
    // Sync language when the component mounts
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage); // Ensure the saved language is also set in i18n
    }
  }, [i18n]);

  return (
    <div className="language-container">
      <Helmet>
        <title>{language === 'tr' ? 'Dil Seçimi - TR' : 'Language Selection - EN'}</title>
        <meta
          name="description"
          content={
            language === 'tr'
              ? 'Dilinizi Türkçe veya İngilizce olarak değiştirmek için tıklayın.'
              : 'Click to switch between English and Turkish.'
          }
        />
      </Helmet>

      <h3 onClick={toggleLanguage} className="language-header">
        <span className="language-span">
          <span className="language-span-text">
            {language === 'tr' ? 'EN' : 'TR'}
          </span>
          <img
            src={language === 'tr' ? '/united-states.png' : '/turkey.png'}
            alt={language === 'tr' ? 'Turkiddededdh Flag' : 'USA Flag'}
            className="flag-img"
          />
        </span>
      </h3>
    </div>
  );
};

export default LanguageComponent;
