import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import { Helmet } from 'react-helmet';
import Loading from './LoadingAnimation'; // Import the Loading component

function App() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [activeSection, setActiveSection] = useState('about');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const projectRefs = useRef([]); // Store refs for each project
  const [counter, setCounter] = useState(0); // Counter state
  const [showLoading, setShowLoading] = useState(false); // Control loading animation
  const [loadingClass, setLoadingClass] = useState(''); // Class for fade effect

  const handleMouseMove = (event) => {
    const clampedX = Math.min(Math.max(event.clientX, 0), window.innerWidth);
    const clampedY = Math.min(Math.max(event.clientY, 0), window.innerHeight);

    setMousePosition({
      x: clampedX,
      y: clampedY,
    });
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleSectionChange = (section) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setActiveSection(section);
      setIsTransitioning(false);
    }, 300);
  };

  // useEffect to handle loading animation whenever counter changes
  useEffect(() => {
    if (counter > 0) { // Only show loading animation after the first increment
      setShowLoading(true);
      setLoadingClass('fade-in'); // Apply fade-in class initially

      // Timeout to simulate loading for 1.5 seconds
      const timer = setTimeout(() => {
        setLoadingClass('fade-out'); // Start fade-out after 1.5s

        // Use a second timeout to hide loading after fade-out completes
        const hideTimer = setTimeout(() => {
          setShowLoading(false); // Hide loading animation after fade-out completes
        }, 750); // Wait for fade-out to complete (0.75s)

        return () => clearTimeout(hideTimer); // Cleanup hide timer
      }, 1500);

      return () => clearTimeout(timer); // Cleanup timer
    }
  }, [counter]); // Depend on counter, trigger when counter changes

  // Function to increment the counter


  return (
    <div className="App">
      {showLoading ? ( 
        <div className={loadingClass}>
          <Loading /> {/* Show loading only when `showLoading` is true */}
        </div>
      ) : (
        <>
          <Helmet>
            <title>My Interactive App</title>
            <meta name="description" content="A dynamic and interactive React application" />
            <meta name="keywords" content="React, Interactive, Dynamic UI" />
          </Helmet>
            
          <LeftPanel
            activeSection={activeSection}
            handleSectionChange={handleSectionChange}
            setCounter={setCounter}
            counter={counter}
          />

          <RightPanel
            activeSection={activeSection}
            isTransitioning={isTransitioning}
            projectRefs={projectRefs}
          />

          <div className="circle-container" style={{
            left: `${mousePosition.x}px`,
            top: `${mousePosition.y}px`,
          }}>
            <div className="circle circle1"></div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
