import React, { useState } from "react";
import { Helmet } from "react-helmet"; // React Helmet import et
import { FaGithub } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const GithubLinkButton = ({ url }) => {
  const [isHovered, setIsHovered] = useState(false);
  const {t}= useTranslation()
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    window.open(url, "_blank");
  };

  return (
    <div style={styles.container}>
      {/* React Helmet ile dinamik başlık ve meta etiketleri ekle */}
      <Helmet>
        <title>Projects</title>
        <meta name="description" content="Click here to visit the GitHub repository for more detailed information." />
      </Helmet>

      <span style={styles.infoText}>{t("For Detailed")}</span>
      <button
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          ...styles.button,
          backgroundColor: isHovered ? "#475569" : "#1e293b",
        }}
      >
        <FaGithub style={styles.icon} />
        GitHub
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    margin: '10px',
  },
  icon: {
    marginRight: '7px',
    fontSize: '14px',
  },
  infoText: {
    marginRight: '10px',
    fontSize: '14px',
    color: '#64748b',
    fontFamily:'Roboto',
    fontWeight:'bold'
  },
};

export default GithubLinkButton;
