// TechnologyCard.js
import React from 'react';

// Reusable card component
const TechnologyCard = ({ name, customStyles = {}, onHoverStyles = {} }) => {
  // Default styles
  const baseStyles = {
    card: {
      borderRadius: '22px',
      padding: '5px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#18354c',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
      ...customStyles.card, // Merge custom styles
    },
    name: {
      fontSize: '0.8rem',
      color: '#5EEAD4',
      transition: 'color 0.2s ease',
      ...customStyles.name, // Merge custom styles
    },
    cardHover: {
      transform: 'scale(1.05)',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
      backgroundColor: '#1c495e',
      ...onHoverStyles.card, // Customize hover styles
    },
    nameHover: {
      color: '#ffffff',
      ...onHoverStyles.name, // Customize hover styles
    },
  };

  // Hover handlers
  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = baseStyles.cardHover.transform;
    e.currentTarget.style.boxShadow = baseStyles.cardHover.boxShadow;
    e.currentTarget.style.backgroundColor = baseStyles.cardHover.backgroundColor;
    e.currentTarget.querySelector('span').style.color = baseStyles.nameHover.color;
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = 'scale(1)';
    e.currentTarget.style.boxShadow = baseStyles.card.boxShadow;
    e.currentTarget.style.backgroundColor = baseStyles.card.backgroundColor;
    e.currentTarget.querySelector('span').style.color = baseStyles.name.color;
  };

  return (
    <div
      style={baseStyles.card}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span style={baseStyles.name}>{name}</span>
    </div>
  );
};

export default TechnologyCard;
