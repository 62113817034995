import { useTranslation } from 'react-i18next';

function useSectionsData() {
  const { t } = useTranslation();

  return {
    about: [t("about")],
    education: [
      t("education"),
      t("education1"),
    ],
    projects: [
      {
        title: t("projects.FlapTalk.title"),
        description: t("projects.FlapTalk.description"),
        features: [
          t("projects.FlapTalk.features.0"),
          t("projects.FlapTalk.features.1"),
          t("projects.FlapTalk.features.2"),
          t("projects.FlapTalk.features.3"),
          t("projects.FlapTalk.features.4"),
        ],
        images: [
          "/Project-images/366495280-d578f1b8-41ce-4325-bb59-747147a219f6.jpeg",
          "/Project-images/366499814-fea36784-1b87-4b7d-9715-c90695238c33.jpeg",
          "/Project-images/366499818-94f9eb70-5b62-4912-ac93-201f2f6187f9.jpeg",
          "/Project-images/366499868-9ae0db81-c8ce-41c3-b1ea-9ab6be6faf77.jpeg",
        ],
        technologies: ['React Native', 'JavaScript', 'Expo CLI', 'Firebase'],
        URLs: ["https://github.com/FatihArslan-cmd/FlapTalk"],
      },
      {
        title: t("projects.CashRegisterApp.title"),
        description: t("projects.CashRegisterApp.description"),
        features: [
          t("projects.CashRegisterApp.features.0"),
          t("projects.CashRegisterApp.features.1"),
          t("projects.CashRegisterApp.features.2"),
          t("projects.CashRegisterApp.features.3"),
          t("projects.CashRegisterApp.features.4"),
        ],
        technologies: ['React Native','JavaScript', 'Expo CLI', 'MockAPI','NativeBase','Competition App'], // Technologies specific to Project 1
        URLs:["https://github.com/FatihArslan-cmd/CashRegisterApp"],
        images: [
          "/Project-images/337922622-1b795358-b5f0-4535-b05a-cc22589905d8.png",
          "/Project-images/337920971-08dfa69d-2b47-43d3-ab19-217162938975.png", // Add your new image paths here
          "/Project-images/337920966-f58aca08-23a4-41a1-b59d-24a596c132b7.png",
          "/Project-images/337921665-f4924977-b690-4f3e-97ee-084baec11944.png"
        ], 
      },
      {
        title: t("projects.MorseCodeApp.title"),
        description: t("projects.MorseCodeApp.description"),
        features: [
          t("projects.MorseCodeApp.features.0"),
          t("projects.MorseCodeApp.features.1"),
          t("projects.MorseCodeApp.features.2"),
          t("projects.MorseCodeApp.features.3"),
          t("projects.MorseCodeApp.features.4"),
        ],
        technologies: ['React Native', 'Expo CLI','TypeScript','Google AdMob','React Native Paper'], 
        URLs:["https://github.com/FatihArslan-cmd/MorseCodeApp"],
        images: [
          "/Project-images/Ekran görüntüsü 2024-09-26 163530.png",
          "/Project-images/Ekran görüntüsü 2024-09-26 163555.png", 
          "/Project-images/Ekran görüntüsü 2024-09-26 163619.png",
          "/Project-images/Ekran görüntüsü 2024-09-26 163701.png"
        ], 
      },
      {
        title: t("projects.WeatherApp.title"),
        description: t("projects.WeatherApp.description"),
        features: [
          t("projects.WeatherApp.features.0"),
          t("projects.WeatherApp.features.1"),
          t("projects.WeatherApp.features.2"),
          t("projects.WeatherApp.features.3"),
          t("projects.WeatherApp.features.4"),
        ],
        technologies: ['React Native','JavaScript', 'Expo CLI', 'OpenWeater'], // Technologies specific to Project 1
        URLs:["https://github.com/FatihArslan-cmd/Weather-App"],
        images: [
          "/Project-images/Ekran görüntüsü 2024-09-26 162731.png",
          "/Project-images/Ekran görüntüsü 2024-09-26 162915.png", // Add your new image paths here
          "/Project-images/Ekran görüntüsü 2024-09-26 162212.png",
          "/Project-images/Ekran görüntüsü 2024-09-26 162253.png"
        ],
      },
      {
        title: t("projects.Cronometer.title"),
        description: t("projects.Cronometer.description"),
        features: [
          t("projects.Cronometer.features.0"),
          t("projects.Cronometer.features.1"),
          t("projects.Cronometer.features.2"),
          t("projects.Cronometer.features.3"),
        ],
        technologies: ['React', 'Electron', 'TypeScript'], // Technologies specific to this project
        URLs:["https://github.com/FatihArslan-cmd/Cronometer-Desktop-App-Electron-/tree/main"],
        images: [
          "/Project-images/Ekran görüntüsü 2024-10-12 133654.png",
        ],
      },
      {
        title: t("projects.ExchangeApp.title"),
        description: t("projects.ExchangeApp.description"),
        features: [
          t("projects.ExchangeApp.features.0"),
          t("projects.ExchangeApp.features.1"),
          t("projects.ExchangeApp.features.2"),
          t("projects.ExchangeApp.features.3"),
        ],
        technologies: ['React Native','JavaScript', 'Expo CLI','Exchangerate-API'], // Technologies specific to Project 1
        URLs:["https://github.com/FatihArslan-cmd/Exchange-App"],
        images: [
          "/Project-images/Ekran görüntüsü 2024-09-26 175510.png",
          "/Project-images/Ekran görüntüsü 2024-09-26 175449.png", // Add your new image paths here
          "/Project-images/Ekran görüntüsü 2024-09-26 175424.png",
        ],
      },
      {
        title: t("projects.BankingWebApp.title"),
        description: t("projects.BankingWebApp.description"),
        features: [
          t("projects.BankingWebApp.features.0"),
          t("projects.BankingWebApp.features.1"),
          t("projects.BankingWebApp.features.2"),
          t("projects.BankingWebApp.features.3"),
          t("projects.BankingWebApp.features.4"),
        ],
        technologies: ['Spring Boot', 'Java', 'RESTful APIs', 'Maven','PostgreSQL','bootstrap'], // Technologies specific to this project
        URLs:["https://github.com/FatihArslan-cmd/BankingWebApp"]
      },
    ],
  };
}

export default useSectionsData;

