import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "title": "Language Selection - EN",
      "subtitle": "Software Engineering Student",
      "description": "Click to switch between English and Turkish.",
      "langLabel": "EN",
      "About": "About",
      "Donate Me": "Donate Me",
      "Education": "Education",
      "Contact Me": "Contact me",
      "Projects": "Projects",
      "For Detailed":"For detailed information",
      "build-text": "I build pixel-perfect, engaging, and accessible digital experiences.",
      "joke-text": "Why do programmers prefer dark mode? Because light attracts bugs! 🐞🪲👾🐛",
      "about": "My name is Fatih Arslan, and I am a Software Engineering student at Sakarya University in Turkey. I specialize in **mobile application development**, particularly focusing on **React Native** to create cross-platform solutions.Throughout my projects, I have extensively worked with **Expo CLI** and **Firebase**, gaining experience in streamlining app development and integrating cloud functionalities for real-time data management.My passion lies in building **highly interactive**, **user-friendly**, and **visually appealing** applications. I am committed to enhancing the **user experience** by refining design and ensuring smooth functionality.In addition to coding, I take a deep interest in the complete lifecycle of mobile applications, from **design** and **prototyping** to **deployment** and **maintenance**.",
      "education": "Bachelor of Science in **Software Engineering** Sakarya University (2022 - now)...",
      "education1": "English Preparatory Education, Sakarya University Faculty of Foreign Languages (2021 - 2022)",
      "projects": {
        "FlapTalk": {
          "title": "📱 FlapTalk",
          "description": "Flap Talk is a versatile messaging application that allows users to connect through various authentication methods, manage their profiles, and communicate with friends securely. Built on Firebase, it offers robust login options via Google, email, and phone verification, ensuring secure access to user accounts.",
          "features": [
            "💬 Real-time chat with Firebase integration",
            "📷 Media sharing: Send photos, videos, and audio",
            "🎨 Theme customization for personalized user experience",
            "📦 Barcode System: Add friends by scanning unique barcodes.",
            "🔐 Firebase Authentication: Secure and reliable login using Google, email, and phone number verification..."
          ]
        },
        "CashRegisterApp": {
          "title": "💰 Cash Register App",
          "description": "The Cash Register Mobile App simplifies point-of-sale for small businesses with features like Face ID, barcode scanning, and email invoices. Users can manage sales, track earnings, view reports, and toggle between dark and light themes, all while supporting 10 languages and dynamic online/offline status.",
          "features": [
            "🔐 Authentication: Secure login using modern methods like Face ID, Touch ID, Barcode scanning...",
            "🛒 Product Sales: Easy interface for selling products and managing transactions",
            "🧾 Invoice Handling: Print invoices and send them to customers via email",
            "🛒 Product Management: Manage over 1000 products using product IDs, favorite lists, and barcode scanning",
            "📷 Barcode Scanning: Effortlessly scan product barcodes using the device's camera for quick product management."
          ]
        },
        "MorseCodeApp": {
          "title": ".-. Morse Code Learning App  ..-",
          "description": "An app for learning and practicing Morse code, featuring a Morse converter, simulation, and support for over 40 languages. Built with React Native.",
          "features": [
            "🛠️ Morse Code Converter: Convert Morse to text and vice versa",
            "🌍 Multi-language support (40+ languages)",
            "🌓 Dark/Light theme customization",
            "🎧 Vibration and sound feedback for better learning",
            "📱 Google Mobile Ads integration"
          ]
        },
        "WeatherApp": {
          "title": "🌤️ Weather App",
          "description": "A weather app providing real-time weather data, forecasts, and local climate trends, built using React Native and open-source APIs.",
          "features": [
            "🌡️ Real-time weather updates",
            "⛅ Detailed forecasts for up to 7 days",
            "🌍 Location-based weather information",
            "📱 Simple and clean UI for easy navigation",
            "🔍 Comprehensive data on UV index, moon phases, air quality, and more"
          ]
        },
        "Cronometer": {
          "title": "Cronometer Desktop App (React Electon)",
          "description": "The Cronometer App is a user-friendly desktop application built with React and Electron, designed to help users effectively track and manage their time. This cross-platform app allows you to set customizable timers, monitor activities, and gain insights into productivity—all in a seamless interface.",
          "features": [
            "⏱️ Stopwatch: Easily start, stop, and reset the stopwatch with a modern UI.",
            "⏳ Countdown Timer: Set a specific time and start counting down with an alert on completion.",
            "🎨 Modern UI: Simple, clean, and responsive user interface.",
            "🔄 Electron-based: Desktop app using Electron with React and TypeScript."
          ]
        },
        "ExchangeApp": {
          "title": "💱 Exchange App",
          "description": "A simple currency exchange app that tracks real-time exchange rates. Built using open-source APIs.",
          "features": [
            "📊 Track exchange rates for multiple currencies",
            "🌍 Global currency support",
            "📱 Intuitive and user-friendly design",
            "📈 Monitor real-time prices of Bitcoin and various altcoins"
          ]
        },
        "BankingWebApp": {
          "title": "Banking FullStack Web App",
          "description": "A comprehensive banking web application with features such as user management, loan request handling, and file uploads. The app includes a robust authentication system and manages various user-related functionalities through dedicated controllers.",
          "features": [
            "🔒 Secure user authentication and registration",
            "📂 File upload management",
            "💼 Loan request processing",
            "📊 Administrative control through dedicated endpoints",
            "⚙️ Global exception handling for smooth user experience"
          ]
        }
      }
    }
  },
  tr: {
    translation: {
      "title": "Dil Seçimi - TR",
      "subtitle": "Yazılım Mühendisliği Öğrencisi",
      "description": "Dilinizi Türkçe veya İngilizce olarak değiştirmek için tıklayın.",
      "langLabel": "TR",
      "About": "Hakkında",
      "Donate Me": "Bağış Yap",
      "Education": "Eğitim",
      "Contact Me": "İletişime Geç",
      "Projects": "Projeler",
      "For Detailed":"Detaylı bilgi için",
      "build-text": "Piksel açısından mükemmel, ilgi çekici ve erişilebilir dijital deneyimler oluşturuyorum.",
      "joke-text": "Programcılar neden karanlık modu tercih ediyor? Çünkü ışık bugları çeker! 🐞👾",
      "about": "Benim adım Fatih Arslan ve Türkiye'deki Sakarya Üniversitesi'nde Yazılım Mühendisliği öğrencisiyim. **Mobil uygulama geliştirme** konusunda uzmanım, özellikle çapraz platform çözümleri oluşturmak için **React Native**'e odaklanıyorum. Projelerim boyunca **Expo CLI** ve **Firebase** ile kapsamlı bir şekilde çalıştım, uygulama geliştirmeyi kolaylaştırma ve gerçek zamanlı veri yönetimi için bulut işlevlerini entegre etme konusunda deneyim kazandım. Tutkum **son derece etkileşimli**, **kullanıcı dostu** ve **görsel olarak çekici** uygulamalar oluşturmaktır. Tasarımı iyileştirerek ve sorunsuz işlevsellik sağlayarak **kullanıcı deneyimini** geliştirmeye kararlıyım. Kodlamaya ek olarak, **tasarım** ve **prototipleme**'den **dağıtım** ve **bakıma** kadar mobil uygulamaların tüm yaşam döngüsüne derin bir ilgi duyuyorum.",
      "education": "**Yazılım Mühendisliği** Lisans Eğitimi, Sakarya Üniversitesi (2022 - halen)",
      "education1": "İngilizce Hazırlık Eğitimi, Sakarya Üniversitesi Yabancı Diller Fakültesi (2021 - 2022)",
      "projects": {
        "FlapTalk": {
          "title": "📱 FlapTalk",
          "description": "Flap Talk, kullanıcıların çeşitli kimlik doğrulama yöntemleriyle bağlantı kurmasını, profillerini yönetmesini ve arkadaşlarıyla güvenli bir şekilde iletişim kurmasını sağlayan çok yönlü bir mesajlaşma uygulamasıdır. Firebase üzerine kurulu olan bu uygulama, Google, e-posta ve telefon doğrulaması aracılığıyla sağlam oturum açma seçenekleri sunarak kullanıcı hesaplarına güvenli erişim sağlar.",
          "features": [
            "💬 Firebase entegrasyonu ile gerçek zamanlı sohbet",
            "📷 Medya paylaşımı: Fotoğraf, video ve ses gönderin",
            "🎨 Kişiselleştirilebilir tema ayarları",
            "📦 Barkod Sistemi: Arkadaşları, benzersiz barkodları tarayarak ekleyin",
            "🔐 Firebase Kimlik Doğrulama: Google, e-posta ve telefon numarası doğrulaması kullanarak güvenli giriş..."
          ]
        },
        "CashRegisterApp": {
          "title": "💰 Kasa Uygulaması",
          "description": "Cash Register Mobil Uygulaması, Face ID, barkod tarama ve e-posta faturaları gibi özelliklerle küçük işletmeler için satış noktasını basitleştirir. Kullanıcılar satışları yönetebilir, kazançları takip edebilir, raporları görüntüleyebilir ve koyu ve açık temalar arasında geçiş yapabilir, tüm bunları 10 dili ve dinamik çevrimiçi/çevrimdışı durumu desteklerken yapabilir.",
          "features": [
            "🔐 Kimlik Doğrulama: Yüz Tanıma, Parmak İzi, Barkod tarama ve e-posta kullanarak güvenli giriş",
            "🛒 Ürün Satışı: Ürün satışı ve işlemleri yönetmek için kolay arayüz",
            "🧾 Fatura İşlemleri: Faturaları yazdırın ve e-posta ile müşterilere gönderin",
            "🛒 Ürün Yönetimi: Ürün kimlikleri, favori listeleri ve barkod tarama ile 1000'den fazla ürünü yönetin",
            "📷 Barkod Tarama: Ürün yönetimi için cihazın kamerasını kullanarak barkodları hızlıca tarayın"
          ]
        },
        "MorseCodeApp": {
          "title": ".-. Mors Kodu Öğrenme Uygulaması  ..-",
          "description": "Morse kodunu öğrenmek ve pratik yapmak için bir uygulama, Morse dönüştürücü, simülasyon ve 40'tan fazla dil desteği içeriyor. React Native ile oluşturuldu.",
          "features": [
            "🛠️ Mors Kodu Dönüştürücü: Mors kodunu metne veya metni Mors koduna dönüştürün",
            "🌍 Çoklu dil desteği (40'tan fazla dil)",
            "🌓 Koyu/Açık tema özelleştirmesi",
            "🎧 Daha iyi öğrenme için titreşim ve sesli geri bildirim",
            "📱 Google Mobil Reklam entegrasyonu"
          ]
        },
        "WeatherApp": {
          "title": "🌤️ Hava Durumu Uygulaması",
          "description": "React Native ve açık kaynaklı API'ler kullanılarak oluşturulmuş, gerçek zamanlı hava durumu verileri, tahminler ve yerel iklim eğilimleri sağlayan bir hava durumu uygulaması.",
          "features": [
            "🌡️ Gerçek zamanlı hava durumu güncellemeleri",
            "⛅ 7 günlük detaylı tahminler",
            "🌍 Konum tabanlı hava bilgisi",
            "📱 Basit ve temiz kullanıcı arayüzü",
            "🔍 UV indeksi, ay evreleri, hava kalitesi gibi kapsamlı veriler"
          ]
        },
"Cronometer": {
  "title": "Cronometer Masaüstü Uygulaması (React Electron)",
  "description": "Cronometer Uygulaması, kullanıcıların zamanlarını etkili bir şekilde takip etmelerine ve yönetmelerine yardımcı olmak için React ve Electron ile oluşturulmuş, kullanıcı dostu bir masaüstü uygulamasıdır. Bu çapraz platform uygulaması, özelleştirilebilir zamanlayıcılar ayarlamanıza, aktiviteleri izlemenize ve üretkenlik hakkında içgörüler edinmenize olanak tanır - hepsi akıcı bir arayüzde.",
  "features": [
    "⏱️ Kronometre: Modern bir arayüzle kronometreyi kolayca başlatın, durdurun ve sıfırlayın.",
    "⏳ Geri Sayım Zamanlayıcısı: Belirli bir süre ayarlayın ve tamamlandığında bir uyarı ile geri sayımı başlatın.",
    "🎨 Modern Arayüz: Basit, temiz ve duyarlı kullanıcı arayüzü.",
    "🔄 Electron Tabanlı: React ve TypeScript ile oluşturulmuş masaüstü uygulaması."
  ]
},

        "ExchangeApp": {
          "title": "💱 Döviz Uygulaması",
          "description": "Gerçek zamanlı döviz kurlarını takip eden basit bir döviz uygulaması. Açık kaynaklı API'ler kullanılarak oluşturulmuştur.",
          "features": [
            "📊 Birden fazla para birimi için döviz kurlarını takip edin",
            "🌍 Küresel para birimi desteği",
            "📱 Kullanıcı dostu ve sezgisel tasarım",
            "📈 Bitcoin ve çeşitli altcoinlerin gerçek zamanlı fiyatlarını izleyin"
          ]
        },
        "BankingWebApp": {
          "title": "FullStack Bankacılık Web Uygulaması",
          "description": "Kullanıcı yönetimi, kredi talebi işleme ve dosya yüklemeleri gibi özelliklere sahip kapsamlı bir bankacılık web uygulaması. Uygulama, sağlam bir kimlik doğrulama sistemi içerir ve özel denetleyiciler aracılığıyla çeşitli kullanıcıyla ilgili işlevleri yönetir.",
          "features": [
            "🔒 Güvenli kullanıcı kimlik doğrulama ve kayıt",
            "📂 Dosya yükleme yönetimi",
            "💼 Kredi talebi işlemleri",
            "📊 Yöneticilere özel kontrol paneli",
            "⚙️ Kesintisiz kullanıcı deneyimi için küresel hata yönetimi"
          ]
        }
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
