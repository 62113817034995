// NavLinks.js
import React from 'react';
import { useTranslation } from 'react-i18next';

function NavLinks({ activeSection, handleSectionChange }) {
  const { t } = useTranslation();

  return (
    <div className="nav-links">
      <button className={activeSection === 'about' ? 'selected' : ''} onClick={() => handleSectionChange('about')}>
        {t('About')}
      </button>
      <button className={activeSection === 'education' ? 'selected' : ''} onClick={() => handleSectionChange('education')}>
        {t('Education')}
      </button>
      <button className={activeSection === 'projects' ? 'selected' : ''} onClick={() => handleSectionChange('projects')}>
        {t('Projects')}
      </button>
    </div>
  );
}

export default NavLinks;
