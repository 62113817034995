import React, { useEffect, useState } from 'react';
import GithubLinkButton from './GithubLinkButton';
import TechnologyCard from './TechnologyCard';
import useSectionsData from './SectionsData';
import { Helmet } from 'react-helmet';

function RightPanel({ activeSection, isTransitioning, projectRefs }) {
  const [displayText, setDisplayText] = useState('');
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  // Call the custom hook to get the data
  const sectionsData = useSectionsData();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.3 }
    );

    projectRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    const educationImageRef = document.querySelector('.education-image');
    if (educationImageRef) observer.observe(educationImageRef);

    return () => {
      projectRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });

      if (educationImageRef) observer.unobserve(educationImageRef);
    };
  }, [activeSection, projectRefs]);

  useEffect(() => {
    if (activeSection === 'about') {
      const fullText = sectionsData?.about?.join(' ') || '';
      let currentIndex = 0;
      let isBold = false;
      let revealedText = '';

      const typingInterval = setInterval(() => {
        if (currentIndex >= fullText.length) {
          clearInterval(typingInterval);
          setIsTypingComplete(true);
          return;
        }

        const char = fullText[currentIndex];

        if (fullText.slice(currentIndex, currentIndex + 2) === '**') {
          isBold = !isBold;
          currentIndex += 2;
        } else {
          if (isBold) {
            revealedText += `<strong>${char}</strong>`;
          } else {
            revealedText += char;
          }
          setDisplayText(revealedText);
          currentIndex++;
        }
      }, 2);

      return () => clearInterval(typingInterval);
    }
  }, [activeSection]);

  const parseBoldText = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  const educationImage = '/Project-images/timthumb.jfif';

  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
  };

  return (
    <div className="right-panel">

      <Helmet>
        <title>{activeSection === 'about' ? 'FatihArslan' : activeSection.charAt(0).toUpperCase() + activeSection.slice(1)}</title>
        <meta name="description" content={`This is the ${activeSection} section`} />
      </Helmet>
      
      <div className={`paragraph-container ${!isTransitioning ? 'active' : ''}`}>
        {activeSection === 'about' ? (
          <div className="paragraph" style={{ color: '#c5d1ed' }}>
            {!isTypingComplete ? (
              <div dangerouslySetInnerHTML={{ __html: displayText }} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: displayText }} />
            )}
          </div>
        ) : activeSection === 'projects' ? (
          sectionsData?.projects?.map((project, index) => (
            <React.Fragment key={index}>
                <div ref={(el) => (projectRefs.current[index] = el)} className="project-item">
                <h2 className="project-title" style={{ textAlign: 'center', color: '#c5d1ed' }}>
                 {project.title}
                </h2>
               <p className="project-description" style={{ color: '#c5d1ed' }}>
    {project.description}
  </p>
  
  <div className="project-features">
    {project.features.map((feature, i) => (
      <p className="project-feature" style={{ color: '#c5d1ed' }} key={i}>
        • {feature}
      </p>
    ))}
  </div>
  
  <div className="project-images">
    {project.images?.map((image, i) => (
      <img
        key={i}
        src={image}
        alt={`Project ${index + 1} Image ${i + 1}`}
        className="project-image"
        onClick={() => openModal(image)}
      />
    ))}
  </div>
  
  <div className="project-urls">
    {project.URLs?.map((URL, i) => (
      <GithubLinkButton key={i} url={URL} />
    ))}
  </div>
  
  <div className="project-technologies"
    style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
      gap: '15px',
    }}
  >
    {project.technologies?.map((tech, i) => (
      <TechnologyCard key={i} name={tech} />
    ))}
  </div>
</div>
              {index !== sectionsData.projects.length - 1 && <div className="divider"></div>}
            </React.Fragment>
          ))
        ) : (
          sectionsData[activeSection]?.map((item, index) => (
            <div className="paragraph" key={index} dangerouslySetInnerHTML={{ __html: parseBoldText(item) }} />
          ))
        )}
      </div>

      {activeSection === 'education' && (
        <a href="https://www.sakarya.edu.tr" target="_blank" rel="noopener noreferrer">
          <img
            src={educationImage}
            loading="lazy"
            alt="Sakarya University"
            className="education-image visible"
            onClick={() => openModal(educationImage)}
          />
        </a>
      )}

      {/* Modal for Full-Screen Image */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content">
            <img src={currentImage} alt="Full Screen" className="full-screen-image" />
          </div>
        </div>
      )}
    </div>
  );
}

export default RightPanel;