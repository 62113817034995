import React from 'react';
import { Helmet } from 'react-helmet';
import PatreonButton from './PatreonButton';
import LanguageComponent from './LanguageComponent';
import { HackerEffectText } from '@nekzus/react-hacker-effect';
import { MatrixRainingLetters } from 'react-mdr';
import { useTranslation } from 'react-i18next';
import SocialIcons from './SocialIcons'; // Import the new SocialIcons component
import NavLinks from './NavLinks'; // Import the NavLinks component

function LeftPanel({ activeSection, handleSectionChange, setCounter, counter }) {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [showJoke, setShowJoke] = React.useState(false);
  const [fadeOut, setFadeOut] = React.useState(false);
  const hoverTimeoutRef = React.useRef(null);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHovered(true);
      setIsAnimating(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    clearTimeout(hoverTimeoutRef.current);
    setTimeout(() => setIsAnimating(false), 1000);
  };

  const handleDescriptionHover = () => {
    setShowJoke(true);
    setFadeOut(false);
  };

  const handleDescriptionLeave = () => {
    setFadeOut(true);
    setTimeout(() => {
      setShowJoke(false);
      setIsAnimating(false);
      setFadeOut(false);
    }, 1000);
  };

  return (
    <div className="left-panel">
      <Helmet>
        <title>{t('title')} - Fatih Arslan</title>
        <meta name="description" content="Fatih Arslan's personal portfolio showcasing software engineering projects, education, and contact information." />
        <meta name="keywords" content="Fatih Arslan, Software Engineering, Portfolio, GitHub, Projects" />
      </Helmet>

      <LanguageComponent setCounter={setCounter} counter={counter} />

      <HackerEffectText initialValue="Fatih Arslan" targetValue="Fatih Arslan" className="hacker-text">
        <div className="content-container"></div>
      </HackerEffectText>

      <p className="subtitle" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {t('subtitle')}
        {(isHovered || isAnimating) && (
          <MatrixRainingLetters key="foo-bar" custom_class={`m-0 p-0 matrix-background ${isHovered ? 'fade-in' : 'fade-out'}`} />
        )}
      </p>

      <p className={`description ${fadeOut ? 'fade-out' : ''}`} onMouseEnter={handleDescriptionHover} onMouseLeave={handleDescriptionLeave}>
        {showJoke ? <span className="joke-text">{t('joke-text')}</span> : <span className="build-text">{t('build-text')}</span>}
      </p>

      {/* Use the NavLinks component */}
      <NavLinks activeSection={activeSection} handleSectionChange={handleSectionChange} />

      <a href="https://www.patreon.com/user?u=124256246" target="_blank" rel="noopener noreferrer">
        <div className="dollar-icon">
          <PatreonButton
            url="https://www.patreon.com/user?u=124256246"
            buttonText="PATREON"
            hoverText={t('Donate Me')}
            styles={{ fontSize: '12px', padding: '10px 20px' }}
          />
        </div>
      </a>

      <SocialIcons />
    </div>
  );
}

export default LeftPanel;
