import React, { useEffect, useState } from "react";

const Loading = () => {
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    // Trigger fade-in by setting isVisible to true when the component mounts
    setIsVisible(true);

    // Optionally, return a cleanup function to handle unmounting if necessary
    return () => {
      setIsVisible(false);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: isMobile ? "blue" : "black", // Change background color for mobile
        opacity: isVisible ? 1 : 0, // Apply fade-in and fade-out via opacity
        transition: "opacity 1s ease-in-out", // Control transition speed and timing
      }}
    >
      <div
        style={{
          height: isMobile ? "100px" : "150px", // Responsive height for mobile
          width: isMobile ? "100px" : "150px", // Responsive width for mobile
          borderRadius: "50%", // Circle shape
          boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.7)", // Bright shadow effect
          background:
            "linear-gradient(90deg, red 0%, yellow 15%, lime 30%, cyan 50%, blue 65%, magenta 80%, red 100%)",
          backgroundSize: "200%",
          animation: "moveGradient 3s linear infinite",
        }}
      ></div>

      <style>{`
        @keyframes moveGradient {
          0% {
            background-position: 0% 0%;
          }
          100% {
            background-position: -200% 0%;
          }
        }

        @media (max-width: 768px) {
          div {
            height: 100px !important;
            width: 100px !important;
            background-color: #0f172a !important; // Set background color to blue for mobile
          }
        }
      `}</style>
    </div>
  );
};

export default Loading;
