import React, { useState } from 'react';
import { FaDollarSign } from 'react-icons/fa'; // Import a dollar sign icon

const PatreonButton = ({ url, buttonText = 'PATREON', hoverText = ' Donate Me', styles: customStyles = {} }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none'}}>
      <button
        style={{
          ...defaultStyles.patreonButton,
          backgroundColor: isHovered ? '#28a745' : '#727f96', // Green on hover
          boxShadow: isHovered
          ? '0 0 20px #111f43, 0 0 40px #28A745' // Glowing effect on hover
          : 'none',
      
          transform: isHovered ? 'scale(1.05)' : 'scale(1)', // Slightly enlarge on hover
          ...customStyles, // Allow custom styles
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered ? <FaDollarSign style={defaultStyles.icon} /> : buttonText}
        {isHovered && hoverText}
      </button>
    </a>
  );
};

const defaultStyles = {
  patreonButton: {
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease', // Add smooth transition
  },
  icon: {
    marginRight: '5px',
    color: '#fff', // Keep the icon color white
    transition: 'color 0.3s ease', // Add smooth transition for the icon color
  },
};

export default PatreonButton;
